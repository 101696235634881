import React from "react";
import { ToastContainer } from "react-toastify";
import PublicRoutes from "./routes/routes";

function App() {
  return (
    <>
      <PublicRoutes />
      <ToastContainer
        style={{ marginTop: "20px" }}
        theme="colored"
        autoClose={true}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
