import React, { useState } from "react";
import {
  AppStoreIcon,
  FacebookIcon,
  GooglePlayIcon,
  InstagramIcon,
  TwitterIcon,
} from "../assets";
import Input from "../components/Input";
import { useFormik } from "formik";
import { DeleteAccountSchema } from "../schema/YupSchema";
import Spinner from "../components/Spinner";
import account from "../features/account";
import { NotifySuccess } from "../components/Toast";
import Header from "../components/Header";

const DeleteAccount = () => {
  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = (payload) => {
    account("requestDeleteAccount", payload)().then((res) => {
      if (res) {
        setLoading(false);
        formik.resetForm();
        NotifySuccess(res.message);
      } else {
        setLoading(false);
      }
    });
  };
  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit(values) {
      setLoading(true);
      handleDeleteAccount(values);
    },
    validationSchema: DeleteAccountSchema,
  });
  return (
    <div className="h-screen">
      <Header />
      <section className="p-20 md:p-40 bg-primary clip-path-style flex justify-between items-center">
        <div className="md:flex">
          <h1 className="text-white md:text-3xl font-extrabold">
            Request for your account to be deleted if its no longer installed on
            your device.
          </h1>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col w-full"
            >
              <Input
                id="email"
                dimension="xl"
                variant="primary"
                {...formik.getFieldProps("email")}
                type="text"
                placeholder={"Email"}
                error={formik.touched.email ? formik.errors.email : undefined}
              />

              <button
                type="submit"
                className="bg-white mt-10 disabled:opacity-70 font-bold rounded text-center text-primary h-10 w-40"
              >
                {loading ? <Spinner /> : "Delete my account"}
              </button>
            </form>
          </div>
        </div>
      </section>

      <section className="px-20 md:px-40 flex flex-col md:flex-row justify-between items-center mt-6">
        <div className=" text-primary md:text-3xl font-extrabold flex flex-col">
          <span>Download the FoodSwipe mobile app and</span>
          <span>Order on the go.</span>

          <ul className="mt-5 flex space-x-5">
            <li>
              <a href="">
                <GooglePlayIcon />
              </a>
            </li>
            <li>
              <a href="">
                <AppStoreIcon />
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-3 flex flex-col items-center">
          <p className="text-primary md:text-3xl font-extrabold">Follow us</p>
          <ul className="mt-5 flex space-x-5">
            <li>
              <a href="https://instagram.com/foodswipe.com.ng?igshid=YmMyMTA2M2Y=">
                <InstagramIcon />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/foodswipe_ng?s=21&t=zfnXbLUXtGbWTdkUvnT77g">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href="">
                <FacebookIcon />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default DeleteAccount;
