import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import DeleteAccount from "../pages/DeleteAccount";

const PublicRoutes = () => {
  return (
    <Routes>
      {/* <Route element={<Navigate to="/home" />} path="/" /> */}
      <Route element={<LandingPage />} path="/" />
      <Route element={<DeleteAccount />} path="/delete-account" />
    </Routes>
  );
};

export default PublicRoutes;
