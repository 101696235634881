import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  return (
    <nav className="bg-primary flex justify-end p-5">
      <ul className="flex text-white space-x-5">
        {location.pathname === "/delete-account" ? (
          <li>
            <Link to="/">Home</Link>
          </li>
        ) : null}

        <li>
          <a href="https://vendor.foodswipe.com.ng/" target="_blank">
            Vendors
          </a>
        </li>
        <li>
          <Link to="/delete-account">Delete Account</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
