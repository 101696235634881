import React from "react";
import {
  AppStoreIcon,
  FacebookIcon,
  GooglePlayIcon,
  InstagramIcon,
  TwitterIcon,
} from "../assets";
import Mockup from "../assets/Mockup.png";
import Header from "../components/Header";

const LandingPage = () => {
  return (
    <div className="h-screen">
      <Header />
      <section className="p-20 md:p-40 bg-primary text-white md:text-3xl font-extrabold clip-path-style flex justify-between items-center">
        <div>
          <h1>Don't want to drive for food?</h1>
          <h1>Your favourite restaurants delivered to your doorstep.</h1>
        </div>

        <img src={Mockup} alt="logo" />
      </section>

      <section className="px-20 md:px-40 flex flex-col md:flex-row justify-between items-center mt-20">
        <div className=" text-primary md:text-3xl font-extrabold flex flex-col">
          <span>Download the FoodSwipe mobile app and</span>
          <span>Order on the go.</span>

          <ul className="mt-5 flex space-x-5">
            <li>
              <a href="">
                <GooglePlayIcon />
              </a>
            </li>
            <li>
              <a href="">
                <AppStoreIcon />
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-10 flex flex-col items-center">
          <p className="text-primary md:text-3xl font-extrabold">Follow us</p>
          <ul className="mt-5 flex space-x-5">
            <li>
              <a href="https://instagram.com/foodswipe.com.ng?igshid=YmMyMTA2M2Y=">
                <InstagramIcon />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/foodswipe_ng?s=21&t=zfnXbLUXtGbWTdkUvnT77g">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href="">
                <FacebookIcon />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
