import axios from "axios";
import { AppConfig } from "../config";

const req = axios.create({
  baseURL: AppConfig.baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    platform: "agent_web",
  },
});

export default req;
