import req from "../axios/req";
import { NotifyError } from "../components/Toast";

const account = (accountType, payload) => {
  const accountOptions = {
    requestDeleteAccount: async () => {
      try {
        const response = await req.post("users/deleteAccountRequest", payload);
        return response.data;
      } catch (error) {
        NotifyError(error.response.data.message);
      }
    },
  };
  return accountOptions[accountType];
};

export default account;
